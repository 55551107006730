import React, {useEffect, useState} from 'react';
import { useRef } from 'react';
import IMask, { InputMask, MaskedOptions } from 'imask';

import { getClassName } from '../../../utils/className';

import style from './Input.module.css'


type IInput = {
    type?: string | 'tel';
    onChange: (eventValue: string, name: string, selectedId?: string | number) => void;
    selectedId?: string
    name: string;
    checked?: boolean;
    size?: boolean;
    disabledInput?: string;
    placeholder?: string;
    radioSize?: boolean;
    onBlur?: (name: string) => void;
    required?: boolean | undefined;
    maskOptions?: MaskedOptions<any>
    value?: string
}

const Input: React.FC<IInput> = (
    {
        type = 'text',
        onChange,
        name,
        checked,
        selectedId,
        size,
        disabledInput,
        placeholder = '',
        radioSize,
        onBlur,
        maskOptions,
        value

    }) => {

    let ref = useRef<HTMLInputElement>(null);
    const [maskedInput, setMaskedInput] = useState<InputMask | null>(null);

    useEffect(() => {
        if (ref.current && maskOptions) {
            debugger;
            const mask = IMask(ref.current, maskOptions);
            setMaskedInput(mask)
        }
    }, [ref])


    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value;

        if (maskedInput) {
            value = maskedInput?.unmaskedValue
        } else {
            value = event.target.value
        }

        onChange(value, name, selectedId)
    }


    const handleBlur = () => {
        onBlur?.(  name)
    }


    const cls = getClassName({
        [style.root]: true,
        [style.customSize]: size,
        [style.disabledInput]: disabledInput,
        [style.radioSize]: radioSize
    })

    return (
        <input className={cls}
               value={value}
               type={type}
               onChange={onChangeHandler}
               placeholder={placeholder}
               name={name}
               checked={checked}
               onBlur={handleBlur}
               ref={ref}
        />
    )
}

export default Input;
