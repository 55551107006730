import axios from '../../utils/axios';

const actionTypes = {
    BASE_INFO: 'BASE_INFO',
    BASE_INFO_SUCCESS: 'BASE_INFO_SUCCESS',
    BASE_INFO_ERROR: 'BASE_INFO_ERROR',
}

export const baseInfo = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.BASE_INFO,
        })
        return axios
            .get('/api/lk/get_base_info')
            .then(res => {
                console.log(res)
                dispatch({
                    type: actionTypes.BASE_INFO_SUCCESS,
                    payload: {
                        fio: res.data.fio,
                        money: res.data.money,
                        reservation_allow: res.data.reservation_allow,
                        promised_pay_allow: res.data.promised_pay_allow,
                        recomended_pay: res.data.recomended_pay,
                        connected: res.data.connected,
                        days_to_stop: res.data.days_to_stop,
                        worlds: res.data.worlds,
                        IP: res.data.IP,
                        reservation: res.data.reservation,
                        promised_pay: res.data.promised_pay,
                        promised_pay_size: res.data.promised_pay_size,
                        tariff: res.data.tariff,
                        balanceMessage: res.data.balance_message,
                        phone: res.data.phone,
                        show_news: res.data.show_news,
                        news: res.data.news
                    }
                })
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.BASE_INFO_ERROR,
                    payload: {
                        message: error.payload.message
                    }
                })
            })
    }
};


